import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import Content from './components/Content.js';

const App = () => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const location = useLocation();
  const navigate = useNavigate();
  
  const [cursorIsOnLink, setCursorIsOnLink] = useState(false);
  const [isTouchScreen, setIsTouchScreen] = useState(false);
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [characters, setCharacters] = useState([]);
  const [homepage, setHomepage] = useState({});
  const [options, setOptions] = useState({});
  const [about, setAbout] = useState({});
  const [previewItem, setPreviewItem] = useState({});
  const [hoveredCharacter, setHoveredCharacter] = useState({});
  const isDragging = useRef(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    let draggingTimeout;

    const handleTouchstart = () => {
      clearTimeout(draggingTimeout);
      draggingTimeout = setTimeout(() => {
        isDragging.current = true;
      }, 400);
    }

    const handleTouchend = () => {
      clearTimeout(draggingTimeout);
      draggingTimeout = setTimeout(() => isDragging.current = false, 200);
    }

    window.addEventListener('touchstart', handleTouchstart);
    window.addEventListener('touchend', handleTouchend);
    window.addEventListener('mousedown', handleTouchstart);
    window.addEventListener('mouseup', handleTouchend);

    return () => {
      window.removeEventListener('touchstart', handleTouchstart);
      window.removeEventListener('touchend', handleTouchend);
      window.removeEventListener('mousedown', handleTouchstart);
      window.removeEventListener('mouseup', handleTouchend);
      clearTimeout(draggingTimeout);
    }

  }, []);

  useEffect(() => {
    setHoveredCharacter({});
  }, [location.pathname]);

  useEffect(() => {
    fetchCockpitData('characters', setCharacters, true, { published: true });
    fetchCockpitData('homepage', setHomepage, false);
    fetchCockpitData('options', setOptions, false);
    fetchCockpitData('about', setAbout, false);
  }, []);

  useEffect(() => {
    if (location.pathname === '/wiki') {
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.touchAction = null;
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    };
    window.addEventListener('resize', handleResize);

    const handleTouch = () => {
      setIsTouchScreen(true);
    }
    window.addEventListener('touchstart', handleTouch);

    const handleWheel = () => {
      setIsTouchScreen(false);
    }
    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('touchstart', handleTouch);
      window.addEventListener('wheel', handleWheel);
    }
  }, []);

  return (
    <Content
      windowDimensions={windowDimensions}
      {...windowDimensions}
      pathname={location.pathname}
      cursorIsOnLink={cursorIsOnLink}
      isTouchScreen={isTouchScreen}
      setIsTouchScreen={setIsTouchScreen}
      setCursorIsOnLink={setCursorIsOnLink}
      menuIsActive={menuIsActive}
      setMenuIsActive={setMenuIsActive}
      characters={characters}
      setCharacters={setCharacters}
      homepage={homepage}
      options={options}
      about={about}
      hoveredCharacter={hoveredCharacter}
      setHoveredCharacter={setHoveredCharacter}
      previewItem={previewItem}
      setPreviewItem={setPreviewItem}
      location={location} navigate={navigate}
      isDragging={isDragging}
      isPreview={isPreview}
      setIsPreview={setIsPreview}
    />
  );
}

export default App;
