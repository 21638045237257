const cockpitUrl = 'https://cms.thesentinelself.com';

const getImage = (path, w, h, q, callback, filter) => {
  fetch(`${cockpitUrl}/api/cockpit/image?token=${process.env.REACT_APP_API_KEY}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      src: path,
      m: ('bestFit'),
      f: filter && filter !== false ? filter : [],         // filter name(s), one or more of: 'blur' | 'brighten' | 'colorize' | 'contrast' | 'darken' | 'desaturate' | 'edge detect' | 'emboss' | 'flip' | 'invert' | 'opacity' | 'pixelate' | 'sepia' | 'sharpen' | 'sketch'
      w: w,           // width
      h: h,           // height
      q: q,           // quality
      d: true        // include full domain path
      // b64: (boolean)      // return base64 encoded image string
    })
  })
  .then(imageUrl => imageUrl.text())
  .then(imageUrl => {
    if (callback) {
      callback(imageUrl);
    }
  })
  .catch(error => console.log(error));
}

const fetchCockpitData = (singletonOrCollectionName, callback, isCollection, filter, fields, limit) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/${isCollection === true ? 'collections' : 'singletons'}/get/${singletonOrCollectionName}?token=${apiKey}`,
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "filter": filter ? filter : null,
        "fields": fields,
        "limit": limit ? limit : null,
        "sort": { _o: 1 }
      })
    }
  )
  .then(response => response.json())
    .then(response => {
      if (isCollection === true) {
        if (response.entries) {
          callback(response.entries);
        }
      } else {
        callback(response);
      }
    })
  .catch(error => console.log(error))
}

const removeCollectionEntry = (collectionName, entryId, callback) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/collections/remove/${collectionName}?token=${apiKey}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      filter: {
        _id: entryId
      }
    })
  })
  .then(response => response.json())
  .then(response => {
    callback(response);
  });
}

const saveCollectionEntry = (collectionName, data, callback, failedCallback) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/collections/save/${collectionName}?token=${apiKey}`, {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: data
    })
  })
  .then(response => response.json())
  .then(response => {
    callback && callback(response);
    if (!callback) {
      console.log('success!');
    }
  })
  .catch(error => {
    failedCallback && failedCallback(error);
    if (!failedCallback) {
      console.log(error);
    }
  });
}

const fetchCockpitSchema = (singletonOrCollectionName, callback, isCollection) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/${isCollection === true ? 'collections' : 'singletons'}/${isCollection === true ? 'collection' : 'singleton'}/${singletonOrCollectionName}?token=${apiKey}`)
  .then(response => response.json())
  .then(response => {
    callback(response);
  })
  .catch(error => {
    console.log(error);
  })
}

const uploadAsset = (file, callback, failedCallback) => {
  const PATH = '/api/cockpit/addAssets?token=';
  const api = cockpitUrl + PATH + process.env.REACT_APP_API_KEY;
  const formData  = new FormData();

  formData.append('files[]', file);

  if (file) {
    if (file.type === 'video/quicktime') {
      typeof failedCallback !== 'undefined' && failedCallback();
      return;
    }
  }

  fetch(api, {
    method: 'POST',
    body: formData
  })
  .then(e => e.json())
  .then(response => {
    const { assets } = response;
    if (!assets) {
      typeof failedCallback !== 'undefined' && failedCallback(response);
      return;
    } else {
      if (assets[0]) {
        typeof callback !== 'undefined' && callback(assets);
      } else {
        typeof failedCallback !== 'undefined' && failedCallback(response);
        return;
      }
    }
  })
  .catch(error => {
    typeof failedCallback !== 'undefined' && failedCallback(error);
  });
}

export { cockpitUrl, fetchCockpitData, removeCollectionEntry, saveCollectionEntry, fetchCockpitSchema, getImage, uploadAsset };