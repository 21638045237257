import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import WysiwygContent from '../components/WysiwygContent';

const Home = (props) => {
  
  const { homepage, windowHeight } = props;
  const { intro, homepageText, continueButtonText } = homepage;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="home page"
      style={{ height: windowHeight + 'px' }}
    >
      <div className="container-fluid page__content__border--top__container">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content__border--top__wrapper">
            <div className="border--top top" />
          </div>
        </div>
      </div>
      <div className="container-fluid page__inner">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content">
            <div className="intro">
              <p className="intro__text">{intro}</p>
            </div>
            {
              homepageText &&
              <div className="text--main">
                <div className="border--top" />
                <WysiwygContent content={homepageText} />
              </div>
            }
          </div>
        </div>
      </div>
      <Link
        to="/wiki"
        className="home__button--wiki"
      >{continueButtonText}</Link>
    </motion.div>
  )
}

export default Home;