import { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { A11y } from '@react-three/a11y';

const Model = (props) => {

  const { numberInCluster, modelClone, index, skeletonAnimations, character, hoveredCharacter, setHoveredCharacter, handleCharacterClick, isTouchScreen, currentModelSize, currentModelSizeMean } = props;
  const { clusterRadius } = character;
  const mouseoverTimeout = useRef({});

  const bones = useRef([]);
  const mesh = useRef();
  const group = useRef({});
  const primitive = useRef();
  const clock = useRef(0);

  useEffect(() => {
    if (hoveredCharacter._id && hoveredCharacter._id !== character._id) {
      clearTimeout(mouseoverTimeout.current);
    }
  }, [hoveredCharacter._id, character._id]);

  useEffect(() => {
    let raf;

    const traverseModelScene = () => {
      if (modelClone) {
        if (bones.current[0]) {
          for (let bone of bones.current) {
            bone.position.x = bone.originalPosition.x;
            bone.position.y = bone.originalPosition.y;
            bone.position.z = bone.originalPosition.z;
            bone.rotation.x = bone.originalRotation._x;
            bone.rotation.y = bone.originalRotation._y;
            bone.rotation.z = bone.originalRotation._z;
          }
          bones.current = [];
        }
        modelClone.traverse(
          (child) => {
            if (child.isBone && skeletonAnimations && skeletonAnimations[0]) {
              for (let boneAnimations of skeletonAnimations) {
                if (child.name === boneAnimations.boneName && boneAnimations.animations && boneAnimations.animations[0]) {
                  child.originalRotation = { ...child.rotation };
                  child.originalPosition = { ...child.position };
                  child.boneAnimations = { ...boneAnimations };
                  bones.current.push(child);
                }
              }
            }
          }
        );
      } else {
        raf = requestAnimationFrame(traverseModelScene);
      }
    };

    raf = requestAnimationFrame(traverseModelScene);

    return () => {
      cancelAnimationFrame(raf);
    };
  }, [modelClone, skeletonAnimations]);

  useFrame(() => {
    if (bones.current) {
      clock.current += 10;
      for (let bone of bones.current) {
        const { boneAnimations } = bone;
        for (let animation of boneAnimations.animations) {
          const { type, axis, amount, speed, offset } = animation;
          const original = type === 'rotation' ? bone.originalRotation[`_${axis}`] : bone.originalPosition[axis];
          bone[type][axis] = -((Math.sin((clock.current + (parseFloat(offset))) * speed)) * amount) + original;
        }
      }
    }
    if (mesh.current && numberInCluster > 1) {
      mesh.current.position.y = (Math.sin((clock.current + index * 500) * 0.002) + modelClone.meshPosition[1]) * 0.4;
    }
  });

  return (
      <group
        ref={group}
        position={numberInCluster > 1 ? [
          modelClone.meshPosition[0] * parseFloat(clusterRadius),
          modelClone.meshPosition[1] * parseFloat(clusterRadius),
          modelClone.meshPosition[2] * parseFloat(clusterRadius)
        ] : [0, 0, 0]}
        scale={[1, 1, 1]}
      >
        <group
          scale={[
            currentModelSize[0] !== 0 ? (1 / currentModelSizeMean) * 1 : 1,
            currentModelSize[0] !== 0 ? (1 / currentModelSizeMean) * 1 : 1,
            currentModelSize[0] !== 0 ? (1 / currentModelSizeMean) * 1 : 1
          ]}
            >
            <A11y type="button" description="Click to find out more">
              <mesh
                onPointerOver={(event) => {
                  clearTimeout(mouseoverTimeout.current);
                  event.stopPropagation();
                  if (props.pathname === '/wiki' && isTouchScreen === false) {
                    setHoveredCharacter(character);
                  }
                }}
                onPointerUp={handleCharacterClick}
                onClick={handleCharacterClick}
                onPointerOut={(event) => {
                  // event.stopPropagation();
                  if (isTouchScreen === false && hoveredCharacter._id === character._id) {
                    mouseoverTimeout.current = setTimeout(() => {
                      setHoveredCharacter({});
                    }, 200);
                  }
                }}
              >
                <boxBufferGeometry args={currentModelSize ? [...currentModelSize, 4, 4, 4] : [1, 1, 1]} />
                <meshBasicMaterial
                  transparent={true}
                  opacity={props.previewItem._id === character._id || window.location.origin.indexOf('http://localhost:') === 0 ? 0.6 : 0}
                  wireframe={props.previewItem._id === character._id || window.location.origin.indexOf('http://localhost:') === 0 ? true : false}
                  // wireframe={false}
                  depthWrite={false}
                />
              </mesh>
            </A11y>
            <mesh
              ref={mesh}
              onPointerOver={(event) => {
                event.stopPropagation();
                clearTimeout(mouseoverTimeout.current);
                if (props.pathname === '/wiki' && isTouchScreen === false) {
                  setHoveredCharacter(character);
                }
              }}
            >
              {
                modelClone &&
                <primitive object={modelClone} ref={primitive} />
              }
            </mesh>
        </group>
      </group>
  )
};

export default Model;