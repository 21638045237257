import { Link } from 'react-router-dom';

const IndexItem = (props) => {

  const { character, windowWidth } = props;
  const { name_slug, name, population, width, height } = character;

  return (
    <li className="index__item">
      <Link to={`/character/${name_slug}`} className="index__item__link row">
      <div className="col-xxs-12 col-sm-4">
        <h3 className="index__item__text index__item__text--heading">{name}</h3>
      </div>
      {
        windowWidth >= 768 &&
        <div className="col-sm-4">
          <p className="index__item__text">{population}</p>
        </div>
      }
      {
        windowWidth >= 768 &&
        <div className="col-sm-4">
            <p className="index__item__text">{width}&times;{height}mm</p>
        </div>
        }
      </Link>
    </li>
  )
};

export default IndexItem;