import { useRef, useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import { A11yAnnouncer } from '@react-three/a11y';
import ThreeScene from './ThreeScene';
import Camera from './Camera';

const ThreeCanvas = (props) => {

  const { windowDimensions, characters, pathname } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [characterPositions, setCharacterPositions] = useState([]);
  const camera = useRef();

  useEffect(() => {

    const characterPositionsArray = [];
    const count = characters.length;
    const radius = Math.PI * 1.4 * characters.length;

    for (let i = 0; i < count; i++) {
      const position = new THREE.Vector3();
      const theta = THREE.MathUtils.degToRad(360 / count * i)
      const phi = THREE.MathUtils.degToRad(360 / count * i)
      position.setFromCylindricalCoords(radius, phi, theta);
      
      characterPositionsArray.push(position);
    }
    
    setCharacterPositions(characterPositionsArray);
  }, [characters.length]);

  return (
    <div
      className="three__wrapper"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px',
        opacity: pathname === '/wiki' ? 1 : 0.6
      }}
    >
      <Canvas
        onCreated={state => {
          state.gl.toneMapping = THREE.CineonToneMapping;
          // state.events.connect(window)
        }}
      >
        <Camera
          ref={camera}
        />
        <ThreeScene
          {...props}
          characterPositions={characterPositions}
        />
      </Canvas>
      <A11yAnnouncer />
    </div>
  )
}

export default ThreeCanvas;