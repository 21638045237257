import { PerspectiveCamera } from '@react-three/drei';

const Camera = (props) => {
  const { camera } = props;

  return (
    <PerspectiveCamera
      makeDefault
      ref={camera}
      fov={27}
      position={[0, 0, 140]}
      rotation={[0, 0, 0]}
      near={1} far={10000}
    >
      <ambientLight color={'blue'} intensity={0.2} />
      <pointLight color={'white'} intensity={0.2} position={[-200, -200, -200]} />
      <pointLight color={'white'} intensity={0.2} position={[200, 200, 200]} />
    </PerspectiveCamera>
  )
};

export default Camera;