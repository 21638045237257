import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { cockpitUrl } from '../utils/cockpit';

const Preview = (props) => {

  const { setPreviewItem, setIsPreview, characters, isPreview, previewItem } = props;
  const [modelsInfo, setModelsInfo] = useState([]);
  const [modelsInfoIsVisible, setModelsInfoIsVisible] = useState(true);

  // useEffect(() => {
  //   if (characters[0]) {
  //     setPreviewItem(characters[0]);
  //     setIsPreview(true);
  //   }
  // }, [characters, setPreviewItem, setIsPreview]);

  useEffect(() => {
    if (previewItem.models) {
      const modelsInfoArray = [];
      let loadedModelsLength = 0;

      const previewModelsLength = previewItem.models.length;

      const handleLoadModel = (model, index) => {
        const meshes = [];
        const bones = [];

        if (model && model.scene) {
          model.scene.traverse(
            (child) => {
              if (child.isMesh) {
                meshes.push(child.name);
              }
              if (child.isBone) {
                bones.push(child.name);
              }
            }
          );
        }

        modelsInfoArray.push({
          ...previewItem.models[index].value,
          model: {
            meshes: meshes,
            bones: bones
          },
          index: index
        });
        loadedModelsLength++;
        if (loadedModelsLength === previewModelsLength) {
          modelsInfoArray.sort((a, b) => a.index - b.index);
          setModelsInfo(modelsInfoArray);
        }
      }

      for (let i = 0; i < previewModelsLength; i++) {
        if (previewItem.models[i].value?.file && previewItem.models[i].value?.file !== '') {
          new GLTFLoader().load(
            cockpitUrl + '/' + previewItem.models[i].value.file,
            // onload
            (data) => handleLoadModel(data, i),
            //onProgress
            (data) => console.log(data.loaded && data.total ? 'loading: ' + data.loaded / data.total * 100 + '%' : 'progress'),
            // onError
            (data) => console.error(data)
          );
        } else {
          handleLoadModel(null, i);
        }
      }
    }
  }, [previewItem.models]);

  useEffect(() => {
    const handleReceiveMessage = (event) => {
      const { data } = event;
      if (data) {
        const { collection, entry } = data;
        if (collection === 'characters') {
          setPreviewItem({ ...entry, collection: 'characters' });
          setIsPreview(true);
        }
      }
    }

    window.addEventListener('message', handleReceiveMessage, false);

    return () => {
      window.removeEventListener('message', handleReceiveMessage, false);
    }
  }, [setPreviewItem, setIsPreview, characters]);

  if (isPreview === true) {
    return (
      <div className="preview__information">
        <div className="preview__links">
          <Link className="preview__link" to="/wiki">View as wiki</Link>
          <Link className="preview__link" to="/">View as homepage</Link>
          {
            previewItem.name_slug && previewItem.name_slug !== '' ?
              <Link className="preview__link" to={`/character/${previewItem.name_slug}`}>View wiki entry</Link>
              :
              <span className="preview__link">Give your character a name to view the wiki entry</span>
          }
        </div>
        {
          modelsInfo[0] &&
          <div className="preview__models__info__wrapper">
            <div className="preview__models__info__header">
              <h2 className="preview__models__info__heading">Models</h2>
              <button
                className="preview__models__info__button"
                onClick={() => setModelsInfoIsVisible(!modelsInfoIsVisible)}
              >{modelsInfoIsVisible === false ? 'Show' : 'Hide'}</button>
            </div>
            {
              modelsInfo[0] && modelsInfoIsVisible === true &&
              <div className="preview__models__info">
                {
                  modelsInfo.map(
                    (model, index) => (
                      <div className="preview__model__info" key={index}>
                        <h3>{model.name}</h3>
                        {
                          model.model.meshes[0] &&
                          <div className="preview__model__info__meshes">
                            <h4>Meshes for materials</h4>
                            {
                              model.model.meshes.map(
                                (mesh, index) => (
                                  <p key={index}>{mesh}</p>
                                )
                              )
                            }
                          </div>
                        }
                        {
                          model.model.bones[0] &&
                          <div className="preview__model__info__bones">
                            <h4>Bones for animations</h4>
                            {
                              model.model.bones.map(
                                (bone, index) => (
                                  <p key={index}>{bone}</p>
                                )
                              )
                            }
                          </div>
                        }
                      </div>
                    )
                  )
                }
              </div>
            }
          </div>
        }
      </div>
    );
  } else {
    return null;
  }
}

export default Preview;