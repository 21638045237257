import LazyloadImage from './LazyloadImage';

const CharacterInfo = (props) => {

  const { character } = props;
  const { profilePicture, name, population, width, height, properties } = character;

  return (
    <div className="col-xxs-12 col-sm-4 col-md-4 offset-md-1 order-sm-2 character__info">
      <div className="character__info__inner row no-margins">
        <div className="col-xxs-12">
          <div className="row profile-picture__row">
            <div className="col-xxs-12 col-xs-8">
              {
                profilePicture?.path ?
                  <LazyloadImage className="profile-picture" image={profilePicture} />
                  :
                <div className="profile-picture profile-picture--placeholder" />
              }
            </div>
          </div>
          <ul className="properties">
            <li className="property row">
              <div className="property__name__wrapper col-xxs-6 col-xs-4">
                <h4 className="property__name">Name</h4>
              </div>
              <div className="property__value__wrapper col-xxs-6 col-xs-8">
                <p className="property__value name">{name}</p>
              </div>
            </li>
            <li className="property row">
              <div className="property__name__wrapper col-xxs-6 col-xs-4">
                <h4 className="property__name">Population</h4>
              </div>
              <div className="property__value__wrapper col-xxs-6 col-xs-8">
                <p className="property__value">{population}</p>
              </div>
            </li>
            <li className="property row">
              <div className="property__name__wrapper col-xxs-6 col-xs-4">
                <h4 className="property__name">Size</h4>
              </div>
              <div className="property__value__wrapper col-xxs-6 col-xs-8">
                <p className="property__value">{width}&times;{height}mm</p>
              </div>
            </li>
            {
              properties &&
              properties[0] &&
              properties.map(
                (property, index) => (
                  <li className="property row" key={index}>
                    <div className="property__name__wrapper col-xxs-6 col-xs-4">
                      <h4 className="property__name">{property.value.name}</h4>
                    </div>
                    <div className="property__value__wrapper col-xxs-6 col-xs-8">
                      <p className="property__value">{property.value.value}</p>
                    </div>
                  </li>
                )
              )
            }
          </ul>
        </div>
      </div>
    </div>
  )
};

export default CharacterInfo;