import { motion } from 'framer-motion'; 
import LinkIcon from '../components/LinkIcon';
import WysiwygContent from '../components/WysiwygContent';

const Essay = (props) => {

  const { about, windowHeight } = props;
  const { intro, aboutText, credits } = about;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="about page"
      style={{ height: windowHeight + 'px' }}
    >
      <div className="container-fluid page__content__border--top__container">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content__border--top__wrapper">
            <div className="border--top top" />
          </div>
        </div>
      </div>
      <div className="container-fluid page__inner">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content">
            <div className="intro">
              <p className="intro__text">{intro}</p>
            </div>
            {
              aboutText &&
              <div className="text--main">
                <div className="border--top" />
                <WysiwygContent content={aboutText} />
              </div>
            }
            {
              credits &&
              <div className="credits">
                <div className="border--top top" />
                  {
                    credits.map(
                      (credit, index) => (
                        <div className="about__credit row" key={index}>
                          <div className="col-xxs-5">
                            <h4 className="about__credit__role">{credit.value.role}</h4>
                          </div>
                          <div className="col-xxs-6">
                            <p className="about__credit__name">{credit.value.name}</p>
                          </div>
                          {
                            credit.value.link && credit.value.link !== '' &&
                            <div className="col-xxs-1">
                              <a href={credit.value.link} target="_blank" className="about__credit__link" rel="noreferrer">
                                <LinkIcon />
                              </a>
                            </div>
                          }
                        </div>
                      )
                    )
                }  
              </div>
            }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Essay;