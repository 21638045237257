import { Link } from 'react-router-dom';
import Menu from './Menu';

const Header = (props) => {

  return (
    <header className="header container-fluid">
      <Link to="/" className="header__title__link">
        <h1 className="title">The Sentinel Self</h1>
      </Link>  
      <Menu {...props} />
    </header>
  )
}

export default Header;