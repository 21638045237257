import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Character = (props) => {
  
  const { character } = props;
  const { _id, name_slug } = character;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/character/${name_slug}`, { replace: true });
  }, [_id, name_slug, navigate]);
  
  return null;
}

export default Character;