import { motion } from 'framer-motion';

const Wiki = (props) => {

  const { options, hoveredCharacter, isTouchScreen } = props;
  const { instructions } = options;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="wiki"
    >
      <p className="wiki__instructions">{isTouchScreen === true && hoveredCharacter._id ? hoveredCharacter.name : instructions}</p>
    </motion.div>
  )
}

export default Wiki;