import { motion } from 'framer-motion';
import CharacterContents from '../components/CharacterContents';
import CharacterSection from '../components/CharacterSection';
import CharacterInfo from '../components/CharacterInfo';
import WysiwygContent from '../components/WysiwygContent';

const Character = (props) => {
  
  const { character, windowHeight } = props;
  const { name, intro, sections } = character;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="character page"
      style={{ height: windowHeight + 'px' }}
    >
      <div className="container-fluid page__content__border--top__container">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content__border--top__wrapper">
            <div className="border--top top" />
          </div>
        </div>
      </div>
      <div className="container-fluid page__inner">
        <div className="row character__row--main">
          <CharacterInfo {...props} />
          <div className="col-xxs-12 col-sm-8 col-md-7 order-sm-1 character__content">
            <h2 className="character__title">{name}</h2>
            <div className="border--top" />
            <div className="intro">
              {
                intro &&
                <div className="character__intro__text">
                  <WysiwygContent content={intro} />
                </div>
              }
            </div>
            <CharacterContents {...props} />
            {
              sections &&
              sections.map(
                (section, index) => (
                  <CharacterSection {...props} section={section} index={index} key={index} />
                )
              )
            }
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Character;