import { Routes, Route } from 'react-router-dom';
import ThreeScene from './Three/ThreeCanvas';
import Header from './Header';
import Cursor from './Cursor';
import Home from '../views/Home';
import Wiki from '../views/Wiki';
import Essay from '../views/Essay';
import Index from '../views/Index';
import Character from '../views/Character';
import CharacterRedirect from '../views/CharacterRedirect';
import Preview from '../utils/Preview';
import { AnimatePresence } from 'framer-motion';

const Content = (props) => {

  const { windowWidth, windowHeight, pathname, hoveredCharacter, isTouchScreen, setIsTouchScreen, characters, options, isPreview } = props;

  return (
    <main
      id="main"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px',
        backgroundColor: options.backgroundColor ? options.backgroundColor : null
      }}
    >
      <div
        className="App"
        onTouchStart={() => setIsTouchScreen(true)}
        onWheel={() => setIsTouchScreen(false)}
      >
        <ThreeScene {...props} />
        {
          isPreview === false &&
          <Header {...props} />
        }
        <AnimatePresence mode="wait">
          <Routes key={pathname}>
            <Route
              path="/"
              key={pathname}
              element={
                <Home {...props} />
              }
            />
            <Route
              path="/wiki"
              key={pathname}
              element={
                <Wiki {...props} />
              }
            />
            <Route
              path="/essay"
              key={pathname}
              element={
                <Essay {...props} />
              }
            />
            <Route
              path="/index-page"
              key={pathname}
              element={
                <Index {...props} />
              }
            />
            {
              characters.map(
                (character) => (
                  <Route
                    path={`/character/${character.name_slug}`}
                    key={character._id}
                    element={
                      <Character {...props} character={character} />
                    }
                  />
                )
              )
            }
            {
              characters.map(
                (character) => (
                  <Route
                    path={`/character/${character._id}`}
                    key={character._id}
                    element={
                      <CharacterRedirect {...props} character={character} />
                    }
                  />
                )
              )
            }
          </Routes>
        </AnimatePresence>
      </div>
      <Preview {...props} />
      {
        pathname === '/wiki' &&
        isTouchScreen === false &&
        hoveredCharacter._id &&
        <Cursor {...props} />
      }
      
    </main>
  )
}

export default Content;