import * as THREE from 'three';
import { useRef, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import lerp from '../../utils/lerp';

const Swarm = ({ count }) => {
  const mesh = useRef();
  const currentMouse = useRef({ x: 4, y: 4 });

  const dummy = useMemo(() => new THREE.Object3D(), [])
  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 100
      const speed = 0.01 + Math.random() / 2000
      const xFactor = -50 + Math.random() * 1000
      const yFactor = -50 + Math.random() * 1000
      const zFactor = -50 + Math.random() * 1000
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  // The innards of this hook will run every frame

  useFrame(({ mouse }) => {
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 2
      const a = Math.cos(t) + Math.sin(t * 1) / 10
      const b = Math.sin(t) + Math.cos(t * 2) / 10
      const s = Math.cos(t)

      currentMouse.current.x = lerp(currentMouse.current.x, mouse.x, 0.01);
      currentMouse.current.y = lerp(currentMouse.current.y, mouse.y, 0.01);

      particle.mx += (currentMouse.current.x - particle.mx)
      particle.my += (currentMouse.current.y * -1 - particle.my)
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
        (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
        (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
      )

      dummy.scale.set(s, s, s)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      if (mesh.current) {
        mesh.current.setMatrixAt(i, dummy.matrix);
      }
    })
    if (mesh.current) {
      mesh.current.instanceMatrix.needsUpdate = true;
    }
  })

  return (
    <group>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <sphereBufferGeometry attach="geometry" args={[0.5, 1]} />
        <meshPhongMaterial
          attach="material"
          color={0xffffff}
          smoothShading
          shininess={0.99}
        />
      </instancedMesh>
    </group>
  )
}

const Particles = (props) => {
  const { pathname } = props;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <group
        scale={[0.4, 0.4, 0.4]}
        position={[-330, -220, -220]}
      >
        <fog color={0xffffff} />
        <Swarm count={isMobile ? 5000 : 10000} pathname={pathname} windowWidth={props.windowWidth} windowHeight={props.windowHeight} />
      </group>
    )
}

export default Particles;
