import { useRef, useState } from 'react';
import CharacterPath from './CharacterPath';
import { useFrame } from '@react-three/fiber';
import lerp from '../../utils/lerp';
import ModelType from './ModelType';

const Character = (props) => {

  const { character, position, navigate, isDragging } = props;
  const { numberInCluster, models, scale } = character;
  const [curve, setCurve] = useState({});
  const [modelSize, setModelSize] = useState([]);

  const mainGroup = useRef({});
  const pathGroup = useRef({});

  useFrame(() => {
    const scaleNumber = parseFloat(scale);
    if (props.pathname === '/wiki') {
      const standardSize = 12;
      mainGroup.current.scale.x = lerp(mainGroup.current.scale.x, standardSize / scaleNumber, 0.1);
      mainGroup.current.scale.y = lerp(mainGroup.current.scale.y, standardSize / scaleNumber, 0.1);
      mainGroup.current.scale.z = lerp(mainGroup.current.scale.z, standardSize / scaleNumber, 0.1);
      mainGroup.current.rotation.x = lerp(mainGroup.current.rotation.x, 0, 0.1);
      mainGroup.current.rotation.y = lerp(mainGroup.current.rotation.y, 0, 0.1);
      mainGroup.current.rotation.z = lerp(mainGroup.current.rotation.z, 0, 0.1);
    } else {
      mainGroup.current.scale.x = lerp(mainGroup.current.scale.x, 1, 0.02);
      mainGroup.current.scale.y = lerp(mainGroup.current.scale.y, 1, 0.02);
      mainGroup.current.scale.z = lerp(mainGroup.current.scale.z, 1, 0.02);
    }
  });

  const handleCharacterClick = () => {
    if (props.pathname === '/wiki') {
      if (isDragging.current === false) {
        navigate(`/character/${character.name_slug}`);
      }
    }
  }

  return (
    <group
      position={position ? [position.x, position.y, position.z] : [0, 0, 0]}
      ref={mainGroup}
    >
      <CharacterPath {...props} curve={curve} setCurve={setCurve} pathGroup={pathGroup} />
      <group
        ref={pathGroup}
        scale={[scale ? parseFloat(scale) : 1, scale ? parseFloat(scale) : 1, scale ? parseFloat(scale) : 1]}
      >
        {
          models &&
          models.map(
            (model, index) => (
              model.value.file && model.value.file !== '' &&
              <ModelType
                {...props}
                character={character}
                numberInCluster={numberInCluster}
                index={index}
                modelItem={model.value}
                key={index}
                modelSize={modelSize}
                setModelSize={setModelSize}
                handleCharacterClick={handleCharacterClick}
              />
            )
          )
        }
      </group>
    </group>
  )
};

export default Character;