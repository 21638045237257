import { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Tube } from '@react-three/drei';
import * as THREE from 'three';
import lerp from '../../utils/lerp';

const CharacterPath = (props) => {

  const { curve, setCurve, character, hoveredCharacter, previewItem, pathGroup, pathname } = props;
  const { path } = character;
  const clock = useRef(0);

  useEffect(() => {
    const handlePositionsArray = [];

    if (path) {
      handlePositionsArray.push(new THREE.Vector3(0, 0, 0));
      for (let item of path) {
        if (item.value) {
          const { x, y, z } = item.value;
          handlePositionsArray.push(
            new THREE.Vector3(
              x ? parseFloat(x) : 0,
              y ? parseFloat(y) : 0,
              z ? parseFloat(z) : 0
            )
          );
        
        }
      }
    }

    if (handlePositionsArray[2]) {
      let theCurve = new THREE.CatmullRomCurve3(handlePositionsArray, true);
      setCurve(theCurve);
    }
  }, [setCurve, path]);

  useFrame(() => {
    if (pathname !== '/wiki') {
      if (path && character.path?.length > 0 && hoveredCharacter._id !== character._id) {
        clock.current += 2;
        const point = Math.sin(0.00025 * clock.current);
        if (curve?.type === "CatmullRomCurve3" && pathGroup.current?.position) {
          if (typeof curve.getPoint === 'function') {
            curve.getPoint(point, pathGroup.current.position);

            const rot = curve.getTangent(point);
            if (rot && rot.x) {
              if (Math.cos(0.0001 * clock.current) > 0) {
                pathGroup.current.rotation.set(rot.x + Math.PI, rot.y + Math.PI, rot.z + Math.PI);
              } else {
                pathGroup.current.rotation.set(rot.x, rot.y, rot.z);
              }
            }
          }
        }
      }
    } else {
      clock.current = 0;
      pathGroup.current.position.x = lerp(pathGroup.current.position.x, 0, 0.1);
      pathGroup.current.position.y = lerp(pathGroup.current.position.y, 0, 0.1);
      pathGroup.current.position.z = lerp(pathGroup.current.position.z, 0, 0.1);
    }
  });

  if (curve.points && curve.points.length > 0 && previewItem._id === character._id) {
    return (  
      <Tube args={[curve, 200, 0.1, 20]}>
        <meshBasicMaterial
          side={THREE.DoubleSide}
          color={'hotpink'}
        />
      </Tube>
    );
  } else {
    return null;
  }
};

export default CharacterPath;