import { useState } from 'react';
import { Link } from 'react-router-dom';

const Menu = (props) => {

  const { pathname, setMenuIsActive } = props;
  const [pages] = useState(['wiki', 'essay']);

  return (
    <nav className="menu">
      {
        pages.map(
          (item, index) => (
            <div
              className={`menu__item${pathname === `/${item}` ? ' active' : ''}`}
              key={index}
            >
              <Link
                onClick={() => setMenuIsActive(false)}
                className="menu__item__link"
                to={`/${item}`}
              >{item}</Link>
            </div>
          )
        )
      }
      <div className={`menu__item${pathname === `/index-page` ? ' active' : ''}`}>
        <Link
          onClick={() => setMenuIsActive(false)}
          className="menu__item__link"
          to={`/index-page`}
        >index</Link>
      </div>
    </nav>
  )
};

export default Menu;