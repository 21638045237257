import WysiwygContent from './WysiwygContent';

const CharacterSection = (props) => {

  const { section, index } = props;
  const { value } = section;
  const { title, title_slug, sectionContent } = value;

  return (
    <div className="character__section" id={title_slug}>
      <div className="character__section__header">
        <h3>{title}</h3> <p>{index}</p>
      </div>
      {
        sectionContent &&
        <div>
          <WysiwygContent content={sectionContent} />
        </div>
      }
    </div>
  )

};

export default CharacterSection;