import { useEffect, useRef } from 'react';

const Cursor = (props) => {

  const { hoveredCharacter } = props;
  const cursor = useRef();

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (cursor.current) {
        if (e.touches && e.touches.length > 0) {
          cursor.current.style.left = e.touches[0].clientX + 20 + 'px';
          cursor.current.style.top = e.touches[0].clientY + 20 + 'px';
        } else {
          cursor.current.style.left = e.clientX + 20 + 'px';
          cursor.current.style.top = e.clientY + 20 + 'px';
        }
      }
    }

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);
    window.addEventListener('touchstart', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchstart', handleMouseMove);
    }
  }, []);

  return (
    <div
      ref={cursor}
      className="cursor"
    >{hoveredCharacter.name}</div>
  )
}

export default Cursor;