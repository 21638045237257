import { useState, useEffect } from 'react';
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { cockpitUrl } from '../../utils/cockpit';
import Model from './Model';

const ModelType = (props) => {

  const { modelItem, isPreviewItem, character, index, modelSize, setModelSize } = props;
  const { file, animations, materials } = modelItem;
  const { numberInCluster, scale, clusterRadius } = character;
  const originalModel = useGLTF(cockpitUrl + '/' + file);
  const [modelClones, setModelClones] = useState([]);
  const [currentModelSize, setCurrentModelSize] = useState([]);
  const [currentModelSizeMean, setCurrentModelSizeMean] = useState(1);

  const [skeletonAnimations, setSkeletonAnimations] = useState([]);

  useEffect(() => {
    const skeletonAnimationsArray = [];
    if (animations && animations[0]) {
      for (let bone of animations) {
        if (bone.value?.boneName && bone.value.boneName !== '') {
          if (bone.value.animations && bone.value.animations[0]) {
            const boneObject = {};
            boneObject.boneName = bone.value.boneName;
            boneObject.animations = [];
            for (let animation of bone.value.animations) {
              boneObject.animations.push(animation.value);
            }
            skeletonAnimationsArray.push(boneObject);
          }
        }
      }
    }
    setSkeletonAnimations(skeletonAnimationsArray);
  }, [animations]);

  useEffect(() => {
    let raf;

    const bounds = {
      min: {},
      max: {}
    };

    let modelSizeArray = [];

    const traverseModelScene = () => {
      if (originalModel.scene) {
        originalModel.scene.traverse(
          (child) => {
            if (child.material) {
              child.material.originalOpacity = child.material.opacity;
              child.material.transparent = true;
            }
            if (child.isMesh) {
              if (materials && materials[0]) {
                for (let material of materials) {
                  if (material.value.meshName === child.name) {
                    const { color, opacity, specular, shininess } = material.value;
                    const newMaterial = new THREE.MeshPhongMaterial({
                      color: color ? color : 0xffffff,
                      specular: specular ? specular : 0xffffff,
                      opacity: opacity ? opacity : 1,
                      transparent: parseFloat(opacity) !== 1 ? true : false,
                      shininess: shininess ? shininess : 1
                    });
                    child.material = newMaterial;
                  }
                }
              }
              if (isPreviewItem === true) {
                console.log(originalModel.name, child.name, 'mesh');
              }
            } else if (child.isBone) {
              if (isPreviewItem === true) {
                console.log(originalModel.name, child.name, 'bone');
              }
            }
            
            if (typeof child.geometry !== 'undefined') {
              child.geometry.computeBoundingBox();
              if (child.geometry.boundingBox?.min && child.geometry.boundingBox?.max) {
                const { min, max } = child.geometry.boundingBox;
                if (!bounds.min.x || min.x < bounds.min.x) { bounds.min.x = min.x; }
                if (!bounds.min.y || min.y < bounds.min.y) { bounds.min.y = min.y; }
                if (!bounds.min.z || min.z < bounds.min.z) { bounds.min.z = min.z; }
                if (!bounds.max.x || max.x > bounds.max.x) { bounds.max.x = max.x; }
                if (!bounds.max.y || max.y > bounds.max.y) { bounds.max.y = max.y; }
                if (!bounds.max.z || max.z > bounds.max.z) { bounds.max.z = max.z; }
              }

              modelSizeArray = [(bounds.max.x - bounds.min.x), (bounds.max.y - bounds.min.y), (bounds.max.z - bounds.min.z)];
              if (index === 0) {
                setModelSize(modelSizeArray);
              }
              setCurrentModelSize(modelSizeArray);
              setCurrentModelSizeMean((modelSizeArray[0] + modelSizeArray[1] + modelSizeArray[2]) / 3);
            }
          }
        );
        if (numberInCluster) {
          const count = parseFloat(numberInCluster);
          const modelClonesArray = [];

          for (let i = 0; i < count; i++) {
            if (i % character.models.length === index) {
              const position = new THREE.Vector3();

              const phi = Math.random() * Math.PI * 2;
              const theta = Math.random() * Math.PI * 2;
              position.setFromSphericalCoords(1, phi, theta).normalize();
              
              const clone = SkeletonUtils.clone(originalModel.scene);

              clone.name = character._id + '_' + i;
              clone.uuid = originalModel.uuid + '_' + i;
              clone.meshPosition = [position.x, position.y, position.z];
              modelClonesArray.push({
                index: i,
                scene: clone
              });
            }
          }
          setModelClones(modelClonesArray);
        }
      } else {
        raf = requestAnimationFrame(traverseModelScene);
      }
    }

    raf = requestAnimationFrame(traverseModelScene);

    return () => {
      cancelAnimationFrame(raf);
    }

  }, [originalModel, numberInCluster, character._id, scale, isPreviewItem, materials, character.models.length, setModelSize, index, clusterRadius]);

  return (
    <group>
      {
        modelClones.map(
          (modelClone, index) => (
            <Model
              {...props}
              modelClone={modelClone.scene}
              skeletonAnimations={skeletonAnimations}
              numberInCluster={numberInCluster}
              index={modelClone.index}
              key={index}
              modelSize={modelSize}
              setModelSize={setModelSize}
              currentModelSize={currentModelSize}
              setCurrentModelSize={setCurrentModelSize}
              currentModelSizeMean={currentModelSizeMean}
            />
          )
        )
      }
    </group>
  )
};

export default ModelType;