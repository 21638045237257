import { HashLink } from 'react-router-hash-link';

const CharacterContents = (props) => {

  const { character } = props;
  const { name_slug, sections } = character;

  return (
    <div className="character__contents">
      <ol className="character__contents__list">
        {
          sections &&
          sections.map(
            (section, index) => (
              <li key={index} className="character__contents__item">
                <HashLink smooth to={`/character/${name_slug}#${section.value.title_slug}`}>
                  {section.value.title}
                </HashLink>
              </li>
            )
          )
        }
      </ol>
    </div>
  )

};

export default CharacterContents;