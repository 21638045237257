import { useRef } from 'react';
import FPSLimiter from '../../utils/FPSLimiter.js';
import Character from './Character.js';
import { useFrame } from '@react-three/fiber';
import { PresentationControls } from '@react-three/drei';
import Particles from './Particles.js';
import lerp from '../../utils/lerp.js';

const ThreeScene = (props) => {

  const { characters, previewItem, characterPositions } = props;
  const charactersGroup = useRef({});
  const clock = useRef(0);
  const mainGroup = useRef();

  useFrame(() => {
    if (mainGroup.current) {
      if (props.pathname !== '/wiki') {
        clock.current += 1;
        let newRotationY = clock.current * 0.005;

        if (props.pathname !== '/') {
          newRotationY = 0.0005;
        }

        let newRotationX = lerp(charactersGroup.current.rotation.x, 0.002, 0.005);
        charactersGroup.current.rotation.y = newRotationY < Math.PI * 2 ? Math.round(newRotationY * 10000) / 10000 : newRotationY % Math.PI * 2;
        charactersGroup.current.rotation.x = newRotationX < Math.PI * 2 ? newRotationX : newRotationX % Math.PI * 2;
        mainGroup.current.position.z = lerp(mainGroup.current.position.z, -90, 0.01);
      } else {
        mainGroup.current.position.z = lerp(mainGroup.current.position.z, -60, 0.01);
        charactersGroup.current.rotation.y = lerp(charactersGroup.current.rotation.y, Math.PI, 0.04);
        charactersGroup.current.rotation.x = lerp(charactersGroup.current.rotation.x, 0, 0.04);
        clock.current = Math.PI / 0.005;
      }
    }
  });

  return (
    <group
      ref={mainGroup}
      position={[0, 0, -120]}
    >
      <FPSLimiter fps={48} />
      <ambientLight color={'white'} intensity={0.3} />
      <pointLight color={'white'} intensity={0.6} />
      <pointLight color={'white'} intensity={0.6} />
      <PresentationControls
        global={true}
        polar={[Math.PI / 6, Math.PI / 3]} // Vertical limits
        config={{ mass: 0.3, tension: 70, friction: 26 }}
      >
        <group
          ref={charactersGroup}
          position={[0, 0, 0]}
        >
          {
            characters &&
            characters.map(
              (character, index) => (
                <Character
                  {...props}
                  character={previewItem._id === character._id ? previewItem : character}
                  key={index}
                  index={index}
                  position={characterPositions[index]}
                  isPreviewItem={previewItem._id === character._id ? true : false}
                />
              )
            )
          }
        </group>
        <Particles
          count={5000}
          windowWidth={props.windowWidth}
          pathname={props.pathname}
        />
      </PresentationControls>
    </group>
  )
};

export default ThreeScene;