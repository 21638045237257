import IndexItem from '../components/IndexItem';
import { motion } from 'framer-motion';

const Index = (props) => {

  const { windowHeight, windowWidth, characters } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="index page"
      style={{ height: windowHeight + 'px' }}
    >
      <div className="container-fluid page__content__border--top__container">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content__border--top__wrapper">
            <div className="border--top top" />
            <div className="row">
              <div className="col-xxs-12 col-sm-4">
                <h2 className="index__heading">Name</h2>
              </div>
              {
                windowWidth >= 768 &&
                <div className="col-sm-4">
                  <h2 className="index__heading">Population</h2>
                </div>
              }
              {
                windowWidth >= 768 &&
                <div className="col-sm-4">
                  <h2 className="index__heading">Size</h2>
                </div>
              }
            </div>
            <div className="border--top" />
          </div>
        </div>
      </div>
      <div className="container-fluid page__inner">
        <div className="row">
          <div className="col-xxs-12 col-sm-8 col-md-7 page__content">
            <ul className="index__list">
              {
                characters.map(
                  (character, index) => (
                    <IndexItem {...props} character={character} index={index} key={index} />
                  )
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default Index;